import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import logo from '../images/logo-strass.png';

function SEO({ description, lang, meta, keywords, title, pathname }) {
  const { site } = useStaticQuery(graphql`
    query DefaultSEOQuery {
      site {
        siteMetadata {
          title
          description
          author
          siteUrl
        }
      }
    }
  `);

  const metaDescription = description || site.siteMetadata.description;
  const pathName = pathname;

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      meta={[
        { name: `description`, content: metaDescription },

        { property: `og:title`, content: title },
        { property: `og:description`, content: metaDescription },
        { property: `og:type`, content: `website` },
        { property: `og:image`, content: site.siteMetadata.siteUrl + logo },
        { property: `og:url`, content: pathName },

        { name: `twitter:title`, content: title },
        { name: `twitter:image`, content: site.siteMetadata.siteUrl + logo },
        { name: `twitter:card`, content: `summary_large_image` },
        { name: `twitter:description`, content: metaDescription },
        { name: `twitter:creator`, content: site.siteMetadata.author }
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `)
              }
            : []
        )
        .concat(meta)}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
    />
  );
}

SEO.defaultProps = {
  lang: `pt-br`,
  keywords: [],
  meta: []
};

SEO.propTypes = {
  description: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
  lang: PropTypes.string,
  meta: PropTypes.array,
  title: PropTypes.string.isRequired,
  pathname: PropTypes.string
};

export default SEO;
