import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/Seo';

function AboutPage() {
  return (
    <section className="px-2 py-6 md:px-16">

      <SEO
        keywords={[`about`, `sobre`, `empresa`, `missao`, `visao`, `valores`]}
        title="About"
      />

      <div className="text-base text-gray-700">
          <div className="px-6 md:px-20 md:mx-20">
                <h4 className="pt-8 text-xl uppercase font-bold">Sobre nós</h4>
                <h4 className="pt-8 uppercase font-bold">Missão</h4>
                <div>
                  <p className="py-2">Gerar resultados dentro da ética e legalidade, para atender as expectativas dos nossos Clientes, Colaboradores e Fornecedores </p>
                  <h4 className="pt-8 uppercase font-bold">Visão</h4>
                  <p className="py-2">Seremos reconhecidos pelos consumidores como a primeira opção no mercado, na satisfação de suas necessidades, superando suas expectativas e assegurando sua fidelidade com excelência no atendimento. Consolidaremos a liderança de mercado, fortalecendo nossa marca, utilizando os diversos canais de vendas, com uma imagem de alta credibilidade, transparência e de ética. Fortaleceremos, cada vez mais, a parceria com nossos fornecedores, por reconhecer a sua importância para o sucesso de nosso negócio. Asseguraremos um crescimento e uma imagem empresarial de inovação, solidez e excelência. Proporcionaremos aos nossos colaboradores um ambiente de trabalho saudável e produtivo, participação no desenvolvimento e resultados da empresa. Desempenhos superiores, competência e comprometimento serão reconhecidos e recompensados. Continuaremos a desenvolver programas de melhoria da qualidade de vida dos nossos colaboradores. Reconhecemos os esforços e desafios que teremos de enfrentar para alcançar essa posição, mas acreditamos profundamente que podemos realizar esse objetivo. Por acreditar nessa Visão, nós a divulgaremos em toda a nossa organização e a compartilharemos com nossos clientes, colaboradores, amigos e parceiros.</p>
                  <h4 className="pt-8 uppercase font-bold">Valores</h4>
                  <p className="py-2">Os valores abaixo retratam a cultura da Empresa e servem como suporte a sua Missão, pelos quais pretende ser reconhecida pela Sociedade em geral e seus Clientes:</p>
                </div>
              </div>
            </div>

    </section>
  );
}

export default AboutPage;
